<template>
  <div>
    <el-button @click="open">跳转</el-button>
  </div>
</template>
<script>
  export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    open(){
      let aa={
        b:'测试',
        c:'对的'
      }
      // window.open('../public/index.html')

      // window.open('/weboffice.html'+JSON.stringify(aa))
    }
  }
};
</script>
<style lang="less" scoped>
</style>

